export default {
    SALES_TERRITORIES: [
        [204,    'East'],
        [207,    'GL/MA'],
        [206,    'West'],
        [205,    'South Central'],
        [203,    'Midwest']
    ],

    SALES_TEAM: [
        [37691, 'Boden Sperr'],
        [28491, 'Buddy Flaherty'],
        [18301, 'Donovan Kelley'],
        [6748, 'Inside Sales*'],
        [33441, 'Jack Ditter'],
        [378, 'Jeremy (J.T.) Timm'],
        [36464, 'John Jeziorski'],
        [3022, 'Jose, Gaytan'],
        [7766, 'Josh Eastman'],
        [35609, 'Kenneth Lyon'],
        [17694, 'Kody Edler'],
        [34149, 'Manuel Perez'],
        [3118, 'Michael Stambook'],
        [8669, 'Mike Larson'],
        [4, 'Pat OBrien'],
        [8039, 'Roger Vaneman'],
        [2174, 'Thomas Dubovich'],
        [7197, 'Troy Skog'],
        [30082, 'Tyler Carlson']
    ]
}