export default {
    SCHEDULED_SHIP_CHANGE_REASONS: [
        [318, 'Customer Request'],
        [319, 'Fill Revenue Goal'],
        [320, 'Consolidation'],
        [321, 'Other'],
        [322, 'Truckload Transit Time'],
        [323, 'Will Call'],
    ],

    SHIPPING_OPTIONS: [
        [198, 'LTL'],
        [199, 'Truckload'],
        [200, 'Will Call'],
    ],

    FEE_TYPES: [
        [1, 'Wait Time (Local)'],
        [2, 'Detention (Truckload)'],
        [3, 'Re-Consignment'],
        [4, 'Lift Gate'],
        [5, 'Re-Delivery'],
        [6, 'TONU'],
        [7, 'Driver Assisted Delivery'],
    ],

    SHIPPING_CSV_OPTIONS: [
        [1, 'Blue Grace'],
        [2, 'Franklin'],
    ],

}