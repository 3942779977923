export default {
    BRANDS: [
        [17, "AAON"],
        [49, "ADDISON"],
        [66, "AEROVENT"],
        [41, "AIR EASE"],
        [74, "AIRSYS"],
        [70, "ALLIED"],
        [54, "AMANA"],
        [68, "AMERISTAR"],
        [19, "ARMSTRONG"],
        [75, "BARD"],
        [60, "BRAND NOT SET"],
        [1, "BRYANT"],
        [61, "CAPTIVEAIRE"],
        [2, "CARRIER"],
        [30, "CDI"],
        [57, "CLIMATE MASTER"],
        [55, "CLIMATROL"],
        [45, "COLEMAN"],
        [24, "COMFORT AIRE"],
        [3, "COMFORTMAKER"],
        [48, "COMMAND AIRE"],
        [67, "DAIKIN"],
        [47, "DAY & NIGHT"],
        [52, "DUCANE"],
        [36, "ERV SYSTEMS"],
        [78, "EUBANK"],
        [51, "FEDDERS"],
        [58, "GAFFERS & STATLER"],
        [4, "GOODMAN"],
        [28, "HEAT CONTROLLER"],
        [46, "HEIL"],
        [18, "ICP"],
        [59, "INNOVENT"],
        [5, "JANITROL"],
        [50, "JOHNSON CONTROLS"],
        [44, "JOHNSTONE"],
        [6, "JOHN ZINK"],
        [33, "KEEPRITE"],
        [43, "KINETICS"],
        [7, "LENNOX"],
        [26, "LUXAIRE"],
        [31, "MAGIC AIRE"],
        [23, "MAMMOTH"],
        [76, "MARVAIR"],
        [40, "MASON"],
        [8, "MCQUAY"],
        [69, "MODINE"],
        [65, "MUNTERS"],
        [22, "NORDYNE"],
        [29, "RENEWAIRE"],
        [27, "REZNOR"],
        [9, "RHEEM"],
        [63, "RUSKIN"],
        [35, "SEMCO"],
        [10, "SINGER"],
        [11, "SNYDER GENERAL"],
        [71, "SOLATUBE"],
        [20, "STERLING"],
        [12, "SUNBEAM"],
        [13, "TAPPAN"],
        [32, "TITAN"],
        [14, "TRANE"],
        [64, "VALENT"],
        [53, "VENMAR"],
        [77, "WALL KING"],
        [15, "WESTINGHOUSE"],
        [62, "XETEX"],
        [16, "YORK"],
    ]
}
