<template>
  <div v-show="state.isGroupBy" class="panel panel-default toggle_container">
    <div class="panel-heading" id="group_by_row">
        <label><input type="checkbox" name="params[date_comparison]"  @click="compareByToggle"> Comparison</label>
    </div>
    <div class="panel-body report_options">
      <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Compare To</label>
        <select class="form-control" name="params[period_type]" v-model="state.optionFields.compareByPeriod.val">
          <option v-for="(field) in state.optionFields.compareByPeriodFields" v-bind:key="field.label" :value="field.val">{{field.label}}</option>
        </select>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Num Periods</label>
        <select class="cdi_select form-control" name="params[date_compare_periods]" v-model="state.optionFields.numPeriod.val">
          <option v-for="(field) in state.optionFields.numPeriods" v-bind:key="field.label" :value="field.val">{{field.label}}</option>
        </select>
      </div>

    </div>
  </div>

</template>

<script>
import { store } from '@/store/Report.store.js'
export default {
  name: "CompareBy",
  data() {
    return {
      state: store.state,
    }
  },
  methods: {
    compareByToggle: function() {
      store.state.isCompareBy = !store.state.isCompareBy;
      store.state.optionFields.compareByPeriodFields.val = 1;
      store.state.optionFields.numPeriods.val = 2;
    },
  }
}
</script>

<style scoped>

</style>